import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.admin');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-nav-admin',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.nav.admin/view.scss */
.navbar-vertical {
  overflow: auto;
  position: relative;
  height: 100%;
}

li.nav-item {
  border-top: 1px solid lightgray;
}
li.nav-item:last-child {
  border-bottom: 1px solid lightgray;
}
li.nav-item.active .nav-link {
  color: var(--wc-blue) !important;
  font-weight: 1000;
}
li.nav-item.active i {
  color: var(--wc-blue);
}
li.nav-item.active::after {
  border-left: 0;
}

.navbar-brand a > * {
  vertical-align: middle;
}
.navbar-brand-image {
  height: 48px;
  margin-top: 16px;
  margin-bottom: 12px;
}`],
})
export class ComponentNavAdminComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0;
    }
}

export default ComponentNavAdminComponent;