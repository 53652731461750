import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.user');
import { OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-nav-user',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/component.nav.user/view.scss */
.navbar {
  border-bottom: 1px solid var(--wc-border);
  background: #fff;
}
.navbar .container {
  justify-content: initial;
  padding: 0;
}
.navbar .navbar-brand img {
  height: 64px;
}
.navbar .navbar-toggler {
  color: var(--wc-blue);
  margin-right: 12px;
}
.navbar .nav-link {
  color: var(--wc-text);
  justify-content: center;
  font-size: 18px;
}
@media (min-width: 769px) {
  .navbar .menu-container {
    width: 100%;
  }
  .navbar .menu-container .wiz-top-menu {
    display: flex;
    justify-content: end;
    padding-top: 16px;
  }
  .navbar .menu-container .wiz-top-menu .wiz-menu-item {
    min-width: 64px;
    padding-left: 16px;
    display: flex;
    justify-content: end;
    align-items: center;
    font-size: 14px;
  }
  .navbar .menu-container .wiz-main-menu {
    display: flex;
    justify-content: end;
    padding: 14px 24px;
    padding-bottom: 4px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item {
    min-width: 108px;
    padding-right: 12px;
    padding-left: 12px;
    font-size: 18px;
  }
  .navbar .menu-container .wiz-main-menu .nav-item .nav-link {
    font-weight: 1000;
    font-family: "main-eb";
  }
  .navbar .menu-container .wiz-main-menu .nav-item .active {
    color: var(--wc-blue);
  }
}
.navbar a {
  color: var(--wc-text);
  cursor: pointer;
}
@media (max-width: 768px) {
  .navbar .navbar-brand img {
    height: 32px;
  }
  .navbar .wiz-top-menu {
    padding-top: 0;
  }
}`],
})
export class ComponentNavUserComponent implements OnInit, OnDestroy {
    public isMenuCollapsed: boolean = true;

    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,    ) { }

    public async ngOnInit() {
        await this.service.init();
        this.iid = setInterval(() => {
            this.ref.detectChanges();
        }, 1000);
    }

    public ngOnDestroy() {
        try {
            clearInterval(this.iid);
        } catch { }
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }

    public sessionTimeout() {
        const s = +new Date();
        const e = +new Date(this.service.auth.session.session_expires_at);

        function formatMicroseconds(milliseconds) {
            let seconds = Math.floor(milliseconds / 1000);
            milliseconds %= 1000;

            let minutes = Math.floor(seconds / 60);
            seconds %= 60;

            let hours = Math.floor(minutes / 60);
            minutes %= 60;

            return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        }

        return formatMicroseconds(e - s);
    }
}

export default ComponentNavUserComponent;