import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.form');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-form',
template: templateSource || '',
    styles: [`

/* file: /opt/wiz/project/main/build/src/app/page.form/view.scss */
.form-control,
.form-select {
  padding: 0 12px !important;
}

.row * {
  line-height: 40px;
}
.row .th {
  width: 200px;
  font-family: "main-eb";
}
.row .col-md-6 .th {
  width: 120px;
}
.row .col-md-4 .th {
  width: 80px;
}
.row .td {
  flex: 1;
}

.row + .row {
  margin-top: 15px;
}

textarea {
  resize: none;
}`],
})
export class PageFormComponent implements OnInit {
    public data = {
        name: "",
        type: "create",
        server_id: null,
        cpu: 4,
        ram: 8,
        disk: 100,
        description: "",
    };
    public myservers = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        await this.loadMyServers();
    }

    private async loadMyServers() {
        const { code, data } = await wiz.call("myservers");
        if (code !== 200) return;
        this.myservers = data;
        await this.service.render();
    }

    public changeType() {
        this.data.name = "";
        this.data.server_id = null;
        this.data.cpu = 4;
        this.data.ram = 8;
        this.data.disk = 100;
        this.service.render();
    }

    public getDisk() {
        try {
            const server = this.myservers.find(it => it.id === this.data.server_id);
            return server.disk;
        } catch { }
        return 0;
    }

    public async apply() {
        const valid = await this.validation();
        if (!valid) return;
        const res = await this.service.alert.show({
            title: "APPLY",
            status: "success",
            message: "입력한 정보로 결재를 올리시겠습니까? 한 번 요청한 결재는 수정할 수 없습니다.",
            action: "apply",
            actionBtn: "success",
            cancel: "cancel",
        });
        if (!res) return;

        const { code } = await wiz.call("apply", this.data);
        if (code !== 200) return await this.service.error("SERVER ERROR");
        await this.service.success("Success to apply");
        location.reload();
    }

    //----
    private async validation() {
        if (this.data.type === "create") {
            if (this.data.name.replace(/\s/g, "").length < 2) {
                await this.service.error("서버 이름은 2글자 이상 입력해주세요.");
                return false;
            }
            if (!/^[a-zA-Z0-9]+$/.test(this.data.name)) {
                await this.service.error("서버 이름은 2글자 이상의 알파벳, 숫자로 구성해주세요.");
                return false;
            }
            if (![2, 4, 8].includes(this.data.cpu * 1)) {
                await this.service.error("CPU는 2, 4, 8 중 하나로 입력해주세요.");
                return false;
            }
            if (this.data.ram < 4 || this.data.ram > 16) {
                await this.service.error("RAM은 4GB ~ 16GB 사이로 입력해주세요.");
                return false;
            }
            if (!Number.isInteger(this.data.disk)) {
                await this.service.error("Disk를 입력해주세요.");
                return false;
            }
            return true;
        }
        if (this.data.type === "upgrade") {
            if (!this.data.server_id || this.data.server_id.length !== 32) {
                await this.service.error("서버를 선택해주세요.");
                return false;
            }
            if (!Number.isInteger(this.data.disk)) {
                await this.service.error("Disk를 입력해주세요.");
                return false;
            }
            return true;
        }
        if (this.data.type === "delete") {
            if (!Number.isInteger(this.data.disk)) {
                await this.service.error("Disk를 입력해주세요.");
                return false;
            }
            return true;
        }
        return true;
    }
}

export default PageFormComponent;