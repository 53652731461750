import '@angular/compiler';
import { PageFormComponent } from './page.form/page.form.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { PortalInfraAdminModalServerAddComponent } from './portal.infra.admin.modal.server.add/portal.infra.admin.modal.server.add.component';
import { PortalSeasonStatusbarComponent } from './portal.season.statusbar/portal.season.statusbar.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalInfraServerReqlogComponent } from './portal.infra.server.reqlog/portal.infra.server.reqlog.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonPagenationComponent } from './portal.season.pagenation/portal.season.pagenation.component';
import { ComponentNavUserComponent } from './component.nav.user/component.nav.user.component';
import { PageAdminComponent } from './page.admin/page.admin.component';
import { PortalInfraServerListComponent } from './portal.infra.server.list/portal.infra.server.list.component';
import { PortalInfraModalServerApplyComponent } from './portal.infra.modal.server.apply/portal.infra.modal.server.apply.component';
import { PortalSeasonLoadingComponent } from './portal.season.loading/portal.season.loading.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { PortalInfraAdminServerRequestComponent } from './portal.infra.admin.server.request/portal.infra.admin.server.request.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PortalSeasonUiDropdownComponent } from './portal.season.ui.dropdown/portal.season.ui.dropdown.component';
import { ComponentNavAdminComponent } from './component.nav.admin/component.nav.admin.component';
import { PortalInfraModalServerInfoComponent } from './portal.infra.modal.server.info/portal.infra.modal.server.info.component';
import { PortalInfraAdminUsersComponent } from './portal.infra.admin.users/portal.infra.admin.users.component';
import { LayoutUserComponent } from './layout.user/layout.user.component';
import { PortalInfraAdminModalServerRequestComponent } from './portal.infra.admin.modal.server.request/portal.infra.admin.modal.server.request.component';
import { PageDashboardComponent } from './page.dashboard/page.dashboard.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeasonTabComponent } from './portal.season.tab/portal.season.tab.component';
import { PortalSeasonViewerTreeComponent } from './portal.season.viewer.tree/portal.season.viewer.tree.component';
import { PortalInfraServerMyrequestComponent } from './portal.infra.server.myrequest/portal.infra.server.myrequest.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [
        AppComponent,
        PageFormComponent,
        PortalSeasonLoadingHexaComponent,
        PortalInfraAdminModalServerAddComponent,
        PortalSeasonStatusbarComponent,
        LayoutEmptyComponent,
        PortalInfraServerReqlogComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonPagenationComponent,
        ComponentNavUserComponent,
        PageAdminComponent,
        PortalInfraServerListComponent,
        PortalInfraModalServerApplyComponent,
        PortalSeasonLoadingComponent,
        LayoutAdminComponent,
        PortalInfraAdminServerRequestComponent,
        PortalSeasonAlertComponent,
        PortalSeasonUiDropdownComponent,
        ComponentNavAdminComponent,
        PortalInfraModalServerInfoComponent,
        PortalInfraAdminUsersComponent,
        LayoutUserComponent,
        PortalInfraAdminModalServerRequestComponent,
        PageDashboardComponent,
        PageLoginComponent,
        PortalSeasonTabComponent,
        PortalSeasonViewerTreeComponent,
        PortalInfraServerMyrequestComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        NgbModule,
        
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }