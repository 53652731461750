import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.dashboard');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-dashboard',
template: templateSource || '',
    styles: [``],
})
export class PageDashboardComponent implements OnInit {

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/login");
        if (this.service.auth.session.role === null) {
            await this.service.error("관리자의 승인 후 사용 가능합니다.");
            return location.href = "/auth/logout";
        }
    }
}

export default PageDashboardComponent;